import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { Field } from 'formik';
import React from 'react';

function ChakraSelect(props) {
  const { label, name, options, ...rest } = props;
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <FormControl isInvalid={form.errors[name] && form.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Select id={name} name={name} {...rest} {...field}>
              {options.map((option) => {
                return (
                  <React.Fragment key={option.key}>
                    <option value={option.value}>{option.key}</option>
                  </React.Fragment>
                );
              })}
            </Select>
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
}

export default ChakraSelect;
