import ChakraCheckbox from '../chakra/ChakraCheckbox';
import ChakraCheckboxGroup from '../chakra/ChakraCheckboxGroup';
import ChakraInput from '../chakra/ChakraInput';
import ChakraPasswordField from '../chakra/ChakraPasswordField';
import ChakraRadioButtons from '../chakra/ChakraRadioButtons';
import ChakraSelect from '../chakra/ChakraSelect';
import ChakraTextarea from '../chakra/ChakraTextarea';

function FormikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case 'input':
      return <ChakraInput {...rest} />;
    case 'textarea':
      return <ChakraTextarea {...rest} />;
    case 'select':
      return <ChakraSelect {...rest} />;
    case 'radio':
      return <ChakraRadioButtons {...rest} />;
    case 'checkboxgroup':
      return <ChakraCheckboxGroup {...rest} />;
    case 'checkbox':
      return <ChakraCheckbox {...rest} />;
    case 'password':
      return <ChakraPasswordField {...rest} />;
    default:
      return null;
  }
}

export default FormikControl;
