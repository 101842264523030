import { Button, ButtonGroup, VisuallyHidden } from '@chakra-ui/react';
import { signIn } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { GoogleIcon } from './ProviderIcons';
const providers = [
  {
    name: 'Google',
    icon: <GoogleIcon boxSize="5" />,
    alias: 'google',
  },
];

export const OAuthButtonGroup = () => {
  const { t } = useTranslation(['auth']);
  const signInWithText = t('auth:oauth-button-group');

  const onSubmit = async (alias) => {
    await signIn(alias);
  };
  return (
    <ButtonGroup variant="outline" spacing="4" width="full">
      {providers.map(({ alias, name, icon }) => (
        <Button
          key={name}
          width="full"
          onClick={() => {
            onSubmit(alias);
          }}
        >
          <VisuallyHidden>
            {signInWithText} {name}
          </VisuallyHidden>
          {icon}
        </Button>
      ))}
    </ButtonGroup>
  );
};
