import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
  Stack,
} from '@chakra-ui/react';
import { Field } from 'formik';

function ChakraCheckbox(props) {
  const { label, name, text, link, linkText, ...rest } = props;
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <FormControl isInvalid={form.errors[name] && form.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <CheckboxGroup>
              <Stack spacing={[1, 5]} direction={['column', 'row']}>
                <Checkbox
                  type="checkbox"
                  id={name}
                  {...field}
                  {...rest}
                  value={name}
                  checked={field.value}
                >
                  {text}
                  {linkText && link && (
                    <Link href={link} isExternal color="blue.500">
                      {linkText}
                      <ExternalLinkIcon mx="2px" />
                    </Link>
                  )}
                </Checkbox>
              </Stack>
            </CheckboxGroup>
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
}

export default ChakraCheckbox;
