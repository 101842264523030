import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react';
import { Field } from 'formik';
import * as React from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';

const ChakraPasswordField = React.forwardRef((props, ref) => {
  const { label, name } = props;
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef(null);
  const mergeRef = useMergeRefs(inputRef, ref);
  const onClickReveal = () => {
    onToggle();

    // if (inputRef.current) {
    //   inputRef.current.focus({ 
    //     preventScroll: true,
    //   });
    // }
  };

  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl mt={4} isInvalid={form.errors[name] && form.touched[name]}>
          <FormLabel  mt={3} htmlFor={name}>{label}</FormLabel>
          <InputGroup>
            <InputRightElement>
              <IconButton
                variant="link"
                aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                icon={isOpen ? <HiEyeOff /> : <HiEye />}
                onClick={onClickReveal}
              />
            </InputRightElement>
            <Input
              id={name}
              ref={mergeRef}
              type={isOpen ? 'text' : 'password'}
              autoComplete="current-password"
              {...props}
              {...field}
            />
          </InputGroup>
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
});
ChakraPasswordField.displayName = 'ChakraPasswordField';

export default ChakraPasswordField;
